import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetCurrentPageIndexAction } from './book-pages.actions';

export interface BookPagesStateModel {
  leftPageIndex: number | null;
  rightPageIndex: number | null;
}

const BOOK_PAGES_TOKEN = new StateToken<BookPagesStateModel>('book_pages');

@State<BookPagesStateModel>({
  name: BOOK_PAGES_TOKEN,
  defaults: {
    leftPageIndex: null,
    rightPageIndex: null
  }
})
@Injectable()
export class BookPagesState {
  constructor() {}

  @Selector() static leftPageIndex({
    leftPageIndex
  }: BookPagesStateModel): number | null {
    return leftPageIndex;
  }

  @Selector() static rightPageIndex({
    rightPageIndex
  }: BookPagesStateModel): number | null {
    return rightPageIndex;
  }

  @Action(SetCurrentPageIndexAction)
  setCurrentPageIndex(
    { patchState }: StateContext<BookPagesStateModel>,
    { pageIndex }: SetCurrentPageIndexAction
  ): void {
    patchState({
      rightPageIndex: pageIndex,
      leftPageIndex: pageIndex - 1 >= 0 ? pageIndex - 1 : null
    });
  }
}
