import { PagesConfigModel } from 'src/app/modules/edit-pages/models/pages-config.model';

export class SetDynamicPagesAction {
  static readonly type = '[BookPages] Set Dynamic Pages';
  constructor(public payload: PagesConfigModel[]) {}
}

export class SetSingleDynamicPageAction {
  static readonly type = '[BookPages] Set Single Dynamic Page';
  constructor(public payload: PagesConfigModel) {}
}

export class SetPagesAction {
  static readonly type = '[BookPages] Set Pages';
  constructor(public payload: PagesConfigModel[][]) {}
}

export class SetCurrentPageIndexAction {
  static readonly type = '[Page] Set Current Page Index';
  constructor(public pageIndex: number) {}
}
